/* General Page Styling */
/* General Page Styling */
html, body {
    height: 100%;
    margin: 0;
    color:#fff;
    overflow-y: auto; /* Ensure the body can scroll */
}
.compare-page {
    background-image: linear-gradient(135deg, #2d2d2d, #000000);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 50px; /* Add some padding to the bottom for extra space */
    overflow-y: auto; /* Allow scrolling */
}

/* Image container for all images in a single row */
.image-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%; /* Full width of the page */
    margin-bottom: 20px;
    margin-top: 70px;
}

/* Images in the grid */
.grid-image {
    width: 22vw; /* Make the images taller than wide */
    height: 33vw; /* Set height to ensure 2x3 aspect ratio */
    object-fit: cover; /* Make sure the image covers its container while keeping aspect ratio */
}

/* The floating rectangle with split sections */
.floating-rectangle {
    width: 50%;
    height: 150px;
    background-color: white;
    margin: -90px auto 30px;
    border-radius: 10px;
    box-shadow: 8px -4px 10px rgba(0, 0, 0, 0.8);
    z-index: 1;
    position: relative;
    display: flex; /* Use flexbox to split into equal sections */
    justify-content: space-between; /* Evenly distribute the space */
    align-items: center;
    padding: 0 10px; /* Add some padding for space */
}

/* Style for the icon and text in the first section */
.section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px;
}

/* Style the icon */
.icon {
    font-size: 36px; /* Adjust the size of the icon */
    color: #363637; /* Icon color */
    margin-bottom: 0px; /* Space between the icon and the text */
}


/* Style the text */
.text {
    font-size: 12px; /* Adjust text size */
    color: #0f3a7f; /* Text color */
    font-weight: 900;
    text-shadow: 0 0 8px rgba(15, 54, 117, 0.4), 0 0 15px rgba(10, 37, 79, 0.1); /* Glowing effect */
    text-align: center; /* Center text within the section */
    white-space: normal; /* Allow the text to wrap */
    word-wrap: break-word; /* Ensure long words wrap correctly */
}

/* Each of the five sections inside the rectangle */
.section {
    flex: 1; /* Each section takes up equal width */
    height: 100%; /* Full height of the rectangle */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section:nth-child(4) {
    background-image: linear-gradient(135deg, #3779FF, #1E40AF); /* Gradient from royal blue to a darker blue */
    color: white; /* White text for this section */
    height: 110%; /* 10% taller */
    border-radius: 10px; /* Optional: Rounded corners to match the rectangle */
    box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.5); /* Diagonal right drop shadow */
}

/* Override the text and icon colors specifically for the 4th section */
.section:nth-child(4) .text {
    color: white; /* White text */
    text-shadow: none; /* No glow effect on white text */
}

.section:nth-child(4) .icon {
    color: white; /* White icon */
}

/* Style the dividing lines between sections, except for the 3rd and 4th section */
.section:not(:last-child):not(:nth-child(3)):not(:nth-child(4))::after {
    content: '';
    position: absolute;
    right: 0;
    top: 25%; /* Start the line at 25% height */
    bottom: 25%; /* Line cuts halfway down */
    width: 2px; /* Thicker line */
    background-color: #ccc; /* Line color */
    border-radius: 5px; /* Rounded edges for the line */
}



/* Container for the compare players tool */
.compare-players-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    gap: 40px;  /* Add space between text and selection */
}

/* Text section for the compare players */
.compare-players-text {
    width: 20%;  /* Same as the similarity tool */
}

/* Title for compare players */
.compare-players-title {
    font-size: 12px;
    color: rgb(213, 182, 8);  /* Gold color */
    letter-spacing: 0.1em;  /* Larger kerning */
    margin-bottom: 0px;
}

/* Description for compare players */
.compare-players-description {
    font-size: 16px;
    color: lightgray;
    line-height: 1.2;
    font-weight: 700;
}

/* Selection section for the compare players tool */
.compare-players-selection {
    width: 20%;  /* Same width for consistency */
}

/* Style the submit button */
.compare-players-selection .submit-button {
    align-self: flex-end;
    margin-top: 0px;
    width: 100%;
}

.similar-players-page {
    background-image: linear-gradient(135deg, #2d2d2d, #000000);
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    flex-direction: column;
}

/* Flexbox container for the tool */
.similarity-tool-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    gap: 40px;
}

/* Set both sections to 20% width on desktop */
.similarity-tool-text,
.similarity-tool-selection {
    width: 20%;
}

/* Style for the similarity tool title */
.similarity-tool-title {
    font-size: 12px;
    color: rgb(213, 182, 8);
    letter-spacing: 0.1em;
    margin-bottom: 0px;
}

/* Style for the tool description */
.similarity-tool-description {
    font-size: 16px;
    color: lightgray;
    line-height: 1.2;
    font-weight: 700;
}

/* Adjust submit button position */
.similarity-tool-selection .submit-button {
    align-self: flex-end;
    margin-top: 0px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .similarity-tool-container {
        flex-direction: column; /* Stack the text and selection vertically */
        align-items: center; /* Center the stacked items */
        gap: 20px;  /* Decrease the gap between items */
    }

    .similarity-tool-text,
    .similarity-tool-selection {
        width: 90%;  /* Expand sections to 90% of the screen width */
        text-align: center; /* Center the text on mobile */
    }

    .similarity-tool-title {
        font-size: 14px;  /* Slightly larger text for mobile */
        margin-bottom: 10px;
    }

    .similarity-tool-description {
        font-size: 14px;  /* Reduce the font size for readability on smaller screens */
        line-height: 1.4;  /* Slightly increase line spacing for readability */
    }

    .similarity-tool-selection .submit-button {
        width: 100%;  /* Make the button full-width on mobile */
        align-self: center; /* Center the button */
        margin-top: 10px;
    }
}

/* Extra small screen styles (for mobile phones in portrait) */
@media (max-width: 480px) {
    .similarity-tool-title {
        font-size: 12px;  /* Further reduce title size for very small screens */
    }

    .similarity-tool-description {
        font-size: 12px;  /* Smaller text for better fit on small screens */
        line-height: 1.4;  /* Keep a good line height for readability */
    }

    .similarity-tool-selection .submit-button {
        margin-top: 20px;  /* Add more space between dropdowns and button */
    }
}


/* Mobile-specific styles for compare players */
@media (max-width: 768px) {
    .compare-players-container {
        flex-direction: column; /* Stack the text and selection vertically */
        align-items: center; /* Center the stacked items */
        gap: 20px;  /* Decrease the gap between items */
    }

    .compare-players-text,
    .compare-players-selection {
        width: 90%;  /* Expand sections to 90% of the screen width */
        text-align: center; /* Center the text on mobile */
    }

    .compare-players-title {
        font-size: 14px;  /* Slightly larger text for mobile */
        margin-bottom: 10px;
    }

    .compare-players-description {
        font-size: 14px;  /* Reduce the font size for readability on smaller screens */
        line-height: 1.4;  /* Slightly increase line spacing for readability */
    }

    .compare-players-selection .submit-button {
        width: 100%;  /* Make the button full-width on mobile */
        align-self: center; /* Center the button */
        margin-top: 10px;
    }
}

/* Extra small screen styles (for mobile phones in portrait) */
@media (max-width: 480px) {
    .compare-players-title {
        font-size: 12px;  /* Further reduce title size for very small screens */
    }

    .compare-players-description {
        font-size: 12px;  /* Smaller text for better fit on small screens */
        line-height: 1.4;  /* Keep a good line height for readability */
    }

    .compare-players-selection .submit-button {
        margin-top: 20px;  /* Add more space between dropdowns and button */
    }
}

/* Mobile-specific styles: shrink the rectangle and lower it */
@media (max-width: 768px) {
    .floating-rectangle {
        width: 75%; /* Shrink the rectangle more on mobile */
        height: 110px; /* Reduce the height for mobile */
        margin: -30px auto 20px; /* Lower the rectangle with less negative margin */
        padding: 0 5px; /* Reduce padding */
    }
    .compare-players-selection {
        width: 70%;
    }

    /* Adjust the font size and icon size for smaller screens */
    .icon {
        font-size: 28px; /* Smaller icons on mobile */
    }

    .text {
        font-size: 10px; /* Smaller text on mobile */
    }
}

/* Extra small screen styles (phones in portrait) */
@media (max-width: 480px) {
    .floating-rectangle {
        width: 90%; /* Make it even smaller on small screens */
        height: 90px; /* Further reduce the height */
        margin: -20px auto 20px; /* Lower the rectangle further */
        padding: 0 5px;
    }

    .icon {
        font-size: 24px; /* Even smaller icons */
    }

    .text {
        font-size: 9px; /* Smaller text */
    }
}