.buysellhold {
    font-size: 0.5em; /* Font size for the player's position */
    color: #ffffff; /* Default color for the player's position */
    padding: 7px 15px; /* Padding for the position box */
    border-radius: 5px; /* Rounded corners for the position box */
    text-align: center; /* Center text within the box */
    float: left; /* Align the element to the left within its container */
  }

  .buysellhold.buy {
    background-color: #28a745; /* Green background for BUY */
    color: #155724; /* Darker green text for BUY */
  }
  
  .buysellhold.sell {
    background-color: #dc3545; /* Red background for SELL */
    color: #721c24; /* Darker red text for SELL */
  }
  
  .buysellhold.hold {
    background-color: #232c30; /* Default background color for HOLD */
    color: #ffffff; /* Default text color for HOLD */
  }



  .buysellhold-mobile {
    font-size: 0.8em; /* Font size for the player's position */
    color: #ffffff; /* Default color for the player's position */
    padding: 7px 15px; /* Padding for the position box */
    border-radius: 5px; /* Rounded corners for the position box */
    text-align: center; /* Center text within the box */
    float: left; /* Align the element to the left within its container */
  }

  .buysellhold-mobile.buy {
    background-color: #28a745; /* Green background for BUY */
    color: #155724; /* Darker green text for BUY */
  }
  
  .buysellhold-mobile.sell {
    background-color: #dc3545; /* Red background for SELL */
    color: #721c24; /* Darker red text for SELL */
  }
  
  .buysellhold-mobile.hold {
    background-color: #131617; /* Default background color for HOLD */
    color: #ffffff; /* Default text color for HOLD */
  }
  