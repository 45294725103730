/* playerspage.css */

.playerspage {
  background-image: url('./../../grunge.png'); /* Adjust the file name if different */
  background-size: cover; /* Cover the entire background */
  background-position: top;
  width: 100vw;
  height: 1100px;
}

.playerspage-mobile {
  background-image: url('./../../grunge.png');
  background-size: cover; /* Cover the area while maintaining aspect ratio */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}



.center2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  font-family: 'Briston', sans-serif;
  padding-top: 100px;
  font-size: 24px;
}


.playerspage h2 {
  font-size: 24px; /* Example font size */
}

.player-card-container {
  position: absolute; /* Absolute positioning relative to the .playerspage container */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the card */
  z-index: 10; /* Ensure the card is above the table */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for 3D effect */
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.player-card-container-mobile {
  height: 80%;
  position: fixed;
  top: 10%;
  left: 0;
  width: 100%;
  z-index: 10;
  min-height: 300px; /* Use min-height instead of fixed height */
  border-radius: 8px;
}

.player-card-container.hidden {
  display: none;
}

.player-card-container-mobile.hidden {
  display: none;
}

.table-container {
  padding-top: 5%;
  display: flex;
  align-items: center;
  position: relative; /* Ensure the table-container is positioned below the player-card-container */

}


.table-container-mobile {
  padding-top: 5%;
  display: flex;
  align-items: center;
  position: relative; /* Ensure the table-container is positioned below the player-card-container */

}