.homepage {
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
    overflow-y: scroll;
    background: linear-gradient(180deg, #2a2a2a, #1e1e1e); /* Gradient background */
    width: 100%;
    text-align: center;
    letter-spacing: 0.05em;
    height: 100vh;
  }
  
  .performanceImage {
    width: 100%; /* Make the image responsive to container width */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensures the entire image fits without cropping */
    display: block; /* Center the image horizontally when using margin */
  }


  p a {
    color: #C6A57E; /* Gold color to match the theme */
    text-decoration: none; /* Removes the underline */
  }
  
  p a:hover {
    color: #FFD700; /* Brighter gold on hover */
    text-decoration: underline; /* Optional: underline on hover */
  }

  .joinButton {
    display: inline-block;
    padding: 12px 24px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    background-color: #C6A57E; /* Gold button */
    border: none;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }


  .additionalSection {
    padding: 40px 20px; 
    width: 100%;
    max-width: 1400px;
    text-align: center;
    border-radius: 8px; 
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Allow children to take full width instead of centering */
    border-bottom: 2px solid #d3d3d3; /* Apply a light gray border to the left */

}
  .sectionTitle {
    text-align: center; /* Center the title within the section */
    margin-bottom: 20px;
    /* border: 1px solid #ccc; */

}

.sectionFooter {
    text-align: center; /* Center the title within the section */
    margin-top: 0px;
    margin-bottom: 20px;
    /* border: 1px solid #ccc; */
}
  
  .sectionTitle h2, .sectionFooter h2 {
    font-size: 2rem;
    color: #fff;
    margin: 0;
  }
  
  .contentContainer {
    display: flex;
    flex-direction: row; /* Horizontal alignment */
    justify-content: center; /* Center all child elements horizontally */
    align-items: center; /* Center all child elements vertically */
    gap: 20px; /* Space between the sections */
    width: 100%; /* Ensure it spans the full width */
}



.textSection, 
.specialization, 
.gamesDisplaySection {
    align-self: center; /* Center this section horizontally if needed */
    width: 100%; /* Ensures it respects full width from parent */
    max-width: 500px; /* Optional limit */
}

.textSection {
    text-align: left;
    color: #fff;
    /* border: 1px solid #ccc; */
    padding: 20px; /* Add padding within the section */
}

.specialization {
    font-family: 'Lato', sans-serif;
    text-align: left; /* Keep text aligned left */
    color: #fff;
    border-left: 2px solid #d3d3d3; /* Apply a light gray border to the left */
    border-right: 2px solid #d3d3d3; /* Apply a light gray border to the left */

    /* border: 1px solid #ccc; */
    padding-left: 20px; /* Maintain padding for internal alignment */
    margin: 0 auto; /* Center horizontally */
    width: fit-content; /* Ensure content width is as per the content */
}

.gamesDisplaySection {
    align-self: center; /* Center horizontally */
    background-color: #1e1e1e; /* Keep consistent styling */
    border-radius: 8px;
    height: auto; /* Let the height adapt to content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* border: 1px solid #ccc; */
    padding: 20px; /* Keep inner padding for better spacing */
}
  
  
  .textSection h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .textSection p {
    font-size: 0.8rem;
    line-height: 1.5;
  }
  

  
  .specialization h4 {
    font-size: 1.2rem;
    margin-bottom: 5px; /* Reduce space below each h4 */
  }
  
  .specialization h4:first-of-type {
    margin-bottom: 0; /* Remove bottom margin for the first h4 */
  }
  
  .specialization h4 + h4 {
    margin-top: 0; /* Remove space between consecutive h4 elements */
  }
  .specialization ul {
    list-style: none;
    padding: 0;
  }
  
  .specialization p {
    font-size: 0.8rem;
  }
  
  
  /* Responsive Design */
  @media (min-width: 768px) {
    .contentContainer {
      flex-direction: row; /* Horizontal layout for larger screens */
      justify-content: space-between;
    }
  
    .textSection, .specialization, .imageSection {
      flex: 1; /* Ensure all columns take equal space */
      max-width: 30%;
    }
  }
  .gold {
    color: #C6A57E; /* Gold color */
  }


  .imageGridSection {
    padding: 20px 0;
    text-align: center;
  }
  
  .imageGrid {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Adjust the gap between images */
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .gridImage {
    width: 100%;
    max-width: 300px; /* Set max-width for each image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Optional shadow for depth */
    overflow: hidden; /* Hide the overflow to ensure the growing image stays within bounds */
    position: relative;
  }
  
  .imgWrapper img {
    width: 100%;
    height: auto;
    transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth animation with easing */
  }
  
  .imgWrapper:hover img {
    transform: scale(1.05); /* Grow image by 5% */
  }
  
  @media (max-width: 768px) {
    .imageGrid {
      flex-direction: column; /* Stack images vertically on smaller screens */
      gap: 15px; /* Reduce gap for smaller screens */
    }
  
    .gridImage {
      max-width: 100%; /* Full width for smaller screens */
    }
  }
  


  .footer {
    padding: 40px 0;
    background-color: #121212; /* Darker background for sleek look */
    color: #fff;
    text-align: center;
    font-family: 'Lato', sans-serif;
    border-top: 2px solid #333; /* Subtle top border */
  }
  
  .footer h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    letter-spacing: 0.05em;
  }
  
  .footer h2 span {
    color: #C6A57E; /* Gold color for emphasis */
  }
  
  .socialLinks {
    display: flex;
    justify-content: center;
    gap: 30px; /* Spacing between icons */
    margin-top: 20px;
  }
  
  .socialIcon {
    font-size: 30px;
    transition: transform 0.3s ease, color 0.3s ease; /* Smooth hover effects */
    color: #bbb; /* Default icon color */
  }
  
  .socialIcon:hover {
    transform: scale(1.2); /* Slightly enlarge icon on hover */
  }
  
  /* Specific brand hover colors */
  .twitter:hover {
    color: #1DA1F2; /* Twitter blue */
  }
  
  .patreon:hover {
    color: #FF424D; /* Patreon red */
  }
  
  .youtube:hover {
    color: #FF0000; /* YouTube red */
  }
  
  .tiktok:hover {
    color: #69C9D0; /* TikTok blue */
  }
  
  .instagram:hover {
    color: #E1306C; /* Instagram pink */
  }
  
  /* Media Queries for Mobile */
  @media (max-width: 768px) {
    .footer h2 {
      font-size: 1.5rem;
    }
  
    .socialLinks {
      gap: 20px; /* Reduce spacing on smaller screens */
    }
  
    .socialIcon {
      font-size: 25px; /* Smaller icons for mobile */
    }
  }
  

  /* Responsive Design */
@media (max-width: 768px) {
    .contentContainer {
      flex-direction: column; /* Stack sections vertically */
      align-items: center;
    }
  
  
    .footer h2 {
      font-size: 1.5rem;
    }
  
    .socialLinks {
      gap: 20px; /* Reduce spacing on smaller screens */
    }
  
    .socialIcon {
      font-size: 25px;
    }
  }



  /* Adjust spacing and alignment */
@media (max-width: 768px) {
    .contentContainer {
      flex-direction: column; /* Stack items vertically */
      gap: 15px; /* Reduce gap for smaller screens */
    }
  
  
    .gamesDisplaySection {
      padding: 15px; /* Adjust padding */
      height: auto; /* Let height adjust naturally */
    }
  }

  /* Reduce image sizes in grid */

  
  @media (max-width: 768px) {
    .footer h2 {
      font-size: 1.5rem; /* Smaller title */
    }
  
    .socialLinks {
      gap: 15px; /* Reduce space between icons */
    }
  
    .socialIcon {
      font-size: 20px; /* Smaller icons */
    }
  }
  

  /* Center text in additionalSection on mobile screens */
@media (max-width: 768px) {
    .additionalSection {
      text-align: center; /* Center text horizontally */
      padding: 20px 0px; /* Add padding for better spacing */
    }
  
    .textSection, .specialization, .gamesDisplaySection {
      text-align: center; /* Center content within these sections */
      margin: 0 auto; /* Ensure sections are centered horizontally */
      padding: 5px 0px 5px 0px; /* Adjust padding for smaller screens */
      max-width: 100%; /* Ensure sections take up full width */
    }
    .specialization {
        margin-bottom: 0px;
        border-top: 2px solid #d3d3d3; /* Apply a light gray border to the left */
        border-bottom: 2px solid #d3d3d3; /* Apply a light gray border to the left */
        border-left: 0px solid #d3d3d3; /* Apply a light gray border to the left */
        padding: 0px 5px 5px 5px; /* Adjust padding for smaller screens */
        border-right: 0px solid #d3d3d3; /* Apply a light gray border to the left */
    }
  
    .textSection h3, .textSection p,
    .specialization h4, .specialization p {
      text-align: center; /* Center text in headers and paragraphs */
      margin-top: 3px;
    }
  }


  @media (max-width: 768px) {
    .imageGrid {
      flex-direction: row; /* Horizontal layout */
      gap: 10px; /* Adjust gap between images */
      overflow-x: auto; /* Enable horizontal scrolling if needed */
      overflow-y: hidden; /* Prevent vertical overflow */
      white-space: nowrap; /* Prevent wrapping */
      justify-content: flex-start; /* Align images at the start */
    }
  
    .gridImage {
      display: inline-block; /* Ensure they behave like inline elements */
      width: 80%; /* Adjust size as necessary */
      max-width: 250px; /* Set max-width to control size on mobile */
      flex: none; /* Prevent images from shrinking */
      margin: 0; /* Remove extra margin */
    }
  }