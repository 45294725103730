.tier {
  font-size: 0.6em;
  /* Font size for the player's position */
  color: #ffffff;
  /* Default color for the player's position */
  padding: 4px 10px;
  /* Padding for the position box */
  border-radius: 5px;
  /* Rounded corners for the position box */
  text-align: center;
  /* Center text within the box */
  float: left;
  /* Align the element to the left within its container */
}

.tier.untouchable {
  background-color: #7232A0;
  /* Green background for BUY */
  color: #ffffff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */

}

.tier.cornerstone {
  background-color: #05AF51;
  /* Green background for BUY */
  color: #ffffff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */
}

.tier.elite,
.tier.foundation {
  background-color: #08B0EF;
  /* Green background for BUY */
  color: #fff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */

}

.tier.base,
.tier.upside {
  background-color: #FEC309;
  /* Green background for BUY */
  color: #fff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */
}

.tier.lowfloor,
.tier.depth {
  background-color: #F4940D;
  /* Green background for BUY */
  color: #fff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */

}

.tier.bust,
.tier.clogger {
  background-color: #FF0100;
  /* Green background for BUY */
  color: #fff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */

}


.tier-mobile {
  font-size: 1em;
  /* Font size for the player's position */
  color: #ffffff;
  /* Default color for the player's position */
  padding: 4px 0px;
  /* Padding for the position box */
  border-radius: 5px;
  /* Rounded corners for the position box */
  text-align: center;
  /* Center text within the box */
  float: left;
  /* Align the element to the left within its container */
}

.tier-mobile span {
  padding: 4px 10px;
  /* Padding for the text inside the box */
}

.tier-mobile.untouchable {
  background-color: #7232A0;
  /* Green background for BUY */
  color: #ffffff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */

}

.tier-mobile.cornerstone {
  background-color: #05AF51;
  /* Green background for BUY */
  color: #ffffff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */
}

.tier-mobile.elite,
.tier-mobile.foundation {
  background-color: #08B0EF;
  /* Green background for BUY */
  color: #fff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */

}
.tier-mobile.upside,
.tier-mobile.base {
  background-color: #FEC309;
  /* Green background for BUY */
  color: #fff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */

}
.tier-mobile.lowfloor,
.tier-mobile.depth {
  background-color: #F4940D;
  /* Green background for BUY */
  color: #fff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */

}
.tier-mobile.bust,
.tier-mobile.clogger {
  background-color: #FF0100;
  /* Green background for BUY */
  color: #fff;
  /* Darker green text for BUY */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8);
  /* Shadow effect */

}