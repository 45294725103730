.type {
    font-size: 0.6em; /* Font size for the player's position */
    color: #ffffff; /* Default color for the player's position */
    padding: 4px 10px; /* Padding for the position box */
    border-radius: 5px; /* Rounded corners for the position box */
    text-align: center; /* Center text within the box */
    margin-top: 10px;
    float: left;
  }
  
  .type.konami {
    background-color: #05AF51; /* Green background for BUY */
    color: #ffffff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */

  }
  
  .type.scramble {
    background-color: #da6309; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }
  
  .type.pocket {
    background-color: #FF0100; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type.runt {
    background-color: #FF0100; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type.hog {
    background-color: #05AF51; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type.gifted {
    background-color: #da6309; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type.freak {
    background-color: #05AF51; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type.raw {
    background-color: #FF0100; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type.bellcow {
    background-color: #05AF51; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type.committee {
    background-color: #FF0100; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type.workhorse {
    background-color: #da6309; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }





  .type-mobile {
    font-size: 1em; /* Font size for the player's position */
    color: #ffffff; /* Default color for the player's position */
    padding: 4px 10px; /* Padding for the position box */
    border-radius: 5px; /* Rounded corners for the position box */
    text-align: center; /* Center text within the box */
    float: left;
  }
  
  .type-mobile.konami {
    background-color: #05AF51; /* Green background for BUY */
    color: #ffffff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */

  }
  
  .type-mobile.scramble {
    background-color: #da6309; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }
  
  .type-mobile.pocket {
    background-color: #FF0100; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type-mobile.runt {
    background-color: #FF0100; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type-mobile.hog {
    background-color: #05AF51; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type-mobile.gifted {
    background-color: #da6309; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type-mobile.freak {
    background-color: #05AF51; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type-mobile.raw {
    background-color: #FF0100; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type-mobile.bellcow {
    background-color: #05AF51; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type-mobile.committee {
    background-color: #FF0100; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }

  .type-mobile.workhorse {
    background-color: #da6309; /* Green background for BUY */
    color: #fff; /* Darker green text for BUY */
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
  }