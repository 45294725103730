
.projections {
    font-size: 25px;
    margin-top: 20px; /* Add some space above the projections */
    font-family: 'Mont', sans-serif; /* Apply Mont for headings */
    font-weight: 700; /* Ensure Mont-Heavy is used for headings */
    
  }
  
  .projections h2 {
    font-size: 1.2em; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add space below the title */
  }
  
  .projection-container {
    display: flex; /* Use Flexbox for layout */
    justify-content: center; /* Center the items horizontally */
    gap: 10px; /* Add some space between items */
  }
  
  .projection1 {
    background-image: url('./../../ProjBG1.png'); /* Adjust the file name if different */
    background-size: cover;
    width: 250px;
    height: 160px;
    display: grid; /* Use CSS Grid for layout within each projection */
    grid-template-rows: auto auto; /* Two rows: one for label, one for value */
    border-radius: 5px;
    background-color: #232c30;
  }

  .projection2 {
    background-image: url('./../../ProjBG2.png'); /* Adjust the file name if different */
    background-size: cover;
    width: 250px;
    height: 160px;
    display: grid; /* Use CSS Grid for layout within each projection */
    grid-template-rows: auto auto; /* Two rows: one for label, one for value */
    border-radius: 5px;
    background-color: #232c30;
  }
  
  
  .label {
    grid-row: 1; /* First row */
    padding-left: 10px; /* Add left padding */
    padding-right: 10px; /* Add right padding */
    font-size: 16px;
    padding-top: 3px;
    font-weight: bold;
  }
  
  .value {
    grid-row: 2; /* Second row */
    font-size: 64px;
    font-family: 'Mont', sans-serif; /* Apply Mont for headings */
    font-weight: 700; /* Ensure Mont-Heavy is used for headings */
  }



  .projections-mobile {
    font-size: 20px;
    font-family: 'Mont', sans-serif; /* Apply Mont for headings */
    font-weight: 700; /* Ensure Mont-Heavy is used for headings */
    color: #ffffff;
  }
  
  .projections-mobile h2 {
    font-size: 1.2em; /* Adjust font size as needed */
  }

  .projections-mobile h3 {
    font-size: 0.8em; /* Adjust font size as needed */
  }
  
  .projection-container-mobile {
    display: flex; /* Use Flexbox for layout */
    justify-content: center; /* Center the items horizontally */
    gap: 10px; /* Add some space between items */
  }
  
  .projection1-mobile {
    background-image: url('./../../ProjBG1.png'); /* Adjust the file name if different */
    background-size: cover;
    width: 30%;
    height: 16%;
    display: grid; /* Use CSS Grid for layout within each projection */
    grid-template-rows: auto auto; /* Two rows: one for label, one for value */
    border-radius: 5px;
    background-color: #232c30;
  }

  .projection2-mobile {
    background-image: url('./../../ProjBG2.png'); /* Adjust the file name if different */
    background-size: cover;
    width: 30%;
    height: 16%;
    display: grid; /* Use CSS Grid for layout within each projection */
    grid-template-rows: auto auto; /* Two rows: one for label, one for value */
    border-radius: 5px;
    background-color: #232c30;
  }
  
  
  .label-mobile {
    grid-row: 1; /* First row */
    font-size: 16px;
    font-weight: bold;
  }
  
  .value-mobile {
    grid-row: 2; /* Second row */
    font-size: 40px;
    font-family: 'Mont', sans-serif; /* Apply Mont for headings */
    font-weight: 700; /* Ensure Mont-Heavy is used for headings */
  }