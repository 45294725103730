/* PlayerTable.css */
.dark-mode-row {
    background-color: #2a2a2a; /* Row background color */
    color: #2a2a2a; /* Row text color */
    font-weight: 700;
  }



/* .position-QB {
  background-color: #1cd531; 
}

.position-RB {
  background-color: #28baa9; 
}

.position-WR {
  background-color: #f85448; 
}

.position-TE {
  background-color: #e7db3d; 
} */
.nowrap-tier {
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Optionally add ellipsis if text overflows */
}

table .nowrap-tier {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Optionally add ellipsis if text overflows */
}

.nowrap-rank {
  white-space: nowrap; /* Prevents the rank text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Optionally add ellipsis if text is too long */
}
/* Customize the filter button */
.ant-table-filter-trigger {
  color: #ffffff !important; /* Change the filter icon color to white */
}

.ant-pagination-item a {
  color: #ffffff !important; /* Set the pagination number color to white */
}

/* Change the color of the active pagination number */
.ant-pagination-item-active a {
  color: #ffffff !important; /* Set the active pagination number color to white */
}

/* Change the color of the "Next" and "Previous" buttons */
.ant-pagination-next a,
.ant-pagination-prev a {
  color: #ffffff !important; /* Set the "Next" and "Previous" button color to white */
}

/* Optional: Change the background color of the "Next" and "Previous" buttons when hovered */
.ant-pagination-next:hover a,
.ant-pagination-prev:hover a {
  color: #e91e63 !important; /* Set the hover color */
}

/* Change the background color of the pagination controls */
.ant-pagination-next,
.ant-pagination-prev {
  background-color: #ffffff !important; /* Set the background color to match the table */
}

/* Change the background color of the pagination controls on hover */
.ant-pagination-next:hover,
.ant-pagination-prev:hover {
  background-color: #e91e63 !important; /* Set the hover background color */
}

/* Optional: Change the background color of active pagination item */
.ant-pagination-item-active {
  background-color: #e91e63 !important; /* Set the active pagination item background color */
}

.even-row {
  background-color: #eeeeee; /* Dark gray for even rows */
}

.tier-UNTOUCHABLE {
    color: #6100ac; /* Text color for 'untouchable' player tier */
}

.tier-CORNERSTONE {
  color: #149824; /* Text color for 'untouchable' player tier */
}

.tier-FOUNDATION {
  color: #13a4c1; /* Text color for 'untouchable' player tier */
}

.tier-BASE {
  color: #8e7f10; /* Text color for 'untouchable' player tier */
}

.tier-DEPTH {
  color: #b8780b; /* Text color for 'untouchable' player tier */
}

.tier-CLOGGER {
  color: #ca1313; /* Text color for 'untouchable' player tier */
}

.tier-BUY {
  color: #1c9607; /* Text color for 'untouchable' player tier */
}

.tier-SELL {
  color: #ca1313; /* Text color for 'untouchable' player tier */
}
.tier-ELITE {
  color: #13a4c1; /* Text color for 'untouchable' player tier */
}
.tier-UPSIDE {
  color: #8e7f10; /* Text color for 'untouchable' player tier */
}
.tier-LOWFLOOR {
  color: #b8780b; /* Text color for 'untouchable' player tier */
}
.tier-BUST {
  color: #ca1313; /* Text color for 'untouchable' player tier */
}

.odd-row {
  background-color: #d5d5d5; /* Light gray for odd rows */
}

/* Add more classes for other positions as needed */

/* Change the color of pagination numbers */
.ant-pagination-item a {
  color: #f85448; /* Set the desired color here */
}

.ant-pagination-item-active a {
  color: #f85448; /* Set the desired active color here */
}

/* Optional: Change the background color of active pagination item */
.ant-pagination-item-active {
  background-color: #f85448; /* Set the desired background color here */
}

.ant-table-column-sort {
  background-color: #d291a7 !important; /* Highlight color for sorted column */
  color: #000 !important; /* Text color for sorted column */
}

.ant-table-thead > tr > th.ant-table-column-sort {
  background-color: #d291a7 !important; /* Highlight for sorted header */
  color: #000 !important; /* Text color for sorted header */
}


/* Change the background color of the pagination footer */
.ant-table-pagination {
  color: #ffffff; /* Set the desired footer text color here */
}

/* Search and buttons container */
.search-and-buttons {
  display: flex;
  justify-content: space-between; /* Align items across the row */
  gap: 10px; /* Space between search and button group */
  align-items: center;
  flex-wrap: nowrap; /* No wrapping on desktop */
}

.search-input {
  flex: 1; /* Let the search bar take up remaining space */
  max-width: 300px; /* Limit search bar width on desktop */
}

.button-group {
  display: flex;
  gap: 10px; /* Space between buttons */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .search-and-buttons {
    flex-direction: column; /* Stack elements vertically */
    gap: 10px; /* Add spacing between search bar and buttons */
  }

  .search-input {
    width: 100%; /* Full width for mobile */
    max-width: none; /* Remove desktop max-width constraint */
  }

  .button-group {
    width: 100%; /* Make buttons full width on mobile */
    gap: 0; /* Remove horizontal gap */
    flex-direction: column; /* Stack buttons vertically */
  }

  .button-group button {
    width: 100%; /* Make each button take the full width */
  }
}
