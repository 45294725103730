.App {
  position: relative;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-tab-container {
  position: relative;
}
.button-tab-container-mobile {
  position: relative;
}

.overlay-button {
  position: absolute;
  top: -0px; /* Adjust this value as needed */
  right: 0; /* Align to the right */
  z-index: 1;
}

.hommeepage {
  background-color: #61dafb;
}

.layout {
  min-height: 100vh;
}

.site-layout-content {
  background: 'transparent';
  padding: 24px;
  min-height: 280px;
}

.ant-layout-header {
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}

.ant-layout-content {
  margin-top: 64px; /* Height of the Ant Design header */
}




/* custom-menu.css */
.ant-menu-submenu-popup {
max-height: 300px; /* Adjust the height as needed */
overflow-y: auto; /* Enable vertical scrolling */
}


/* src/App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.ant-layout {
  flex: 1;
}

.ant-layout-header {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}

.ant-layout-content {
  margin-top: 30px; /* Ensure this matches the height of your header */
  padding: 24px;
  background: #f0f2f5;
  flex: 1;
}
