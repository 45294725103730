@font-face {
  font-family: 'Mont';
  src: url('../../Mont-ExtraLight.otf') format('truetype');
  font-weight: 200; /* Adjust the font weight as per your font file */
  font-style: normal; /* Adjust font style if your font has different styles */
}

@font-face {
  font-family: 'Mont';
  src: url('../../Mont-Heavy.otf') format('truetype');
  font-weight: 700; /* Adjust the font weight as per your font file */
  font-style: normal; /* Adjust font style if your font has different styles */
}


.card-mobile {
  background-image: url('./../../cardBG.png') !important;
  background-size: cover;
  background-color: #181E21;
  border-radius: 10px;
  box-shadow: 16px 16px 16px rgba(0, 0, 0, 0.8);
  padding: 10px 10px 20px 20px;
  text-align: center;
  margin: 20px auto;
  position: relative;
  height: 90%; /* or any value that fits */
  width: 90%;
}

.info-pair-mobile {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text within each item */
  margin: 0 10px; /* Adjust margin between pairs */
}

.buildtype-mobile {
  font-weight: 700;
  text-decoration: underline;
  color: #ffffff;
  padding-top: 10px
}
  
.player-card-mobile h2 {
  font-size: 1.0em; /* Larger font size for the name */
  margin: 10px 0; /* Space around the name */
  color: #ffffff;
}


.player-card-mobile h1 {
  font-size: 3em; /* Larger font size for the name */
  margin: 10px 0; /* Space around the name */
  color: #ffffff;
}

.player-card-mobile p {
  margin: 5px 0; /* Space around the paragraphs */
  color: #d3d3d3;
}

.player-card-mobile .position {
  font-size: 1em; /* Font size for the player's position */
  color: #ffffff; /* Color for the player's position */
  background-color: #232c30; /* Background color for the position box */
  padding: 7px 15px; /* Padding for the position box */
  border-radius: 5px; /* Rounded corners for the position box */
  display: inline-flex; /* Use inline-flex for centering content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  min-width: auto; /* Remove any existing min-width */
  max-height: 20px; /* Set a maximum height */
  height: 100%; /* Ensure the height is 100% of its parent */
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.8); /* Shadow effect */
}


.sectional-small-mobile {
  width: 100%; /* Set width of the section */
  height: 50px; /* Set height of the section, auto adjusts to content */
  padding: 20px 10px 10px 0px; /* Optional: Add padding */
  margin: 00px 0; /* Optional: Add margin */
  display: flex;
  align-items: flex-start;
  /* border: 1px solid #ccc; Optional: Add a border */
}


.sectional-big-mobile {
  width: 100%; /* Set width of the section */
  padding: 0px 0px 10px 10px; /* Optional: Add padding */
  margin: 00px 0; /* Optional: Add margin */
  border-bottom: 1px solid #ccc;
  /* border: 1px solid #ccc; Optional: Add a border */
}

.content-wrapper-mobile {
  display: flex;
  flex-direction: row;
}


.column-container-mobile {
  display: flex; /* Use flexbox for layout */
}


.column-mobile {
  position: relative; /* Set the column as the reference point for absolutely positioned children */
  overflow: hidden; /* Prevent content overflow */
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  flex-shrink: 0; /* Prevent columns from shrinking */
  /* border: 1px solid #ccc */
}

.player-info-mobile {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  gap: 15px; /* Add space between items */
  padding-top: 5px;
  color: #ffffff;
}

.centered-wrapper-mobile {
  display: flex;
  margin-top: -10px;
  justify-content: start;
  width: 100%;
}

.centered-player-info-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Keep children left-aligned */
}

.tall-mobile {
  position: relative; /* Set the column as the reference point for absolutely positioned children */
  width: 400px; /* Set the width of each column */
  height: 1000px;
  padding-left: 10px;
  padding-right: 10px;
  /* border: 1px solid #ccc; */
  flex-shrink: 0; /* Prevent columns from shrinking */
}

.main-content-wrapper-mobile {
  display: flex; /* Use flexbox to layout children side by side */
  justify-content: space-between; /* Space out columns evenly */
  width: 844px;
  height: auto; /* Adjust height as needed */
}

/* Wrap columns and tall div side by side */
.column-and-tall-wrapper-mobile {
  display: flex;
  justify-content: space-between; /* Space out columns evenly */
  width: 100%;
  margin-bottom: 0px; /* Add space below for the stats table */
}


.player-img-mobile {
  position: absolute; /* Position the image absolutely within the player card */
  top: 0; /* Align the top of the image with the top of the card */
  left: 0; /* Align the left side of the image with the left side of the card */
  margin-left: -100px;
  width: 120%; /* Make the image take the full width of the card */
  height: auto; /* Maintain the aspect ratio of the image */
  border-radius: 0; /* Remove the border radius if you want the full-size image */
  overflow: visible; /* Allow the image to overflow the card */
}


.column-container-mobile::after {
  content: ''; /* Add content for pseudo-element */
  position: absolute; /* Position absolutely */
  top: 0; /* Align to the top */
  bottom: 0; /* Align to the bottom */
  left: 660px; /* Position the line at 400 pixels from the left */
  /* width: 1px; Set width of the line */
  background-color: #ffffff; /* Color of the line */
}



.player-info2-mobile {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  gap: 15px; /* Add space between items */
  padding-bottom: 10px;
}

.player-info-mobile h1 {
  margin: 0; /* Remove default margin from h1 elements */
  text-align: left; /* Ensure text aligns to the left */
}

.position-mobile {
  font-size: 1em; /* Font size for the player's position */
  color: #ffffff; /* Default color for the player's position */
  background-color: #232c30; /* Default background color for the position box */
  padding: 7px 15px; /* Padding for the position box */
  width: 30px;
  border-radius: 5px; /* Rounded corners for the position box */
  display: inline-block; /* Display as inline-block to adjust width dynamically */
  text-align: center; /* Center text within the box */
}

.player-card-mobile .buysellhold {
  font-size: 1em; /* Font size for the player's position */
  color: #ffffff; /* Color for the player's position */
  background-color: #232c30; /* Background color for the position box */
  padding: 7px 15px; /* Padding for the position box */
  border-radius: 5px; /* Rounded corners for the position box */
  display: inline-flex; /* Use inline-flex for centering content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  min-width: auto; /* Remove any existing min-width */
  max-height: 20px; /* Set a maximum height */
  height: 100%; /* Ensure the height is 100% of its parent */
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.8); /* Shadow effect */
}


.teamnamelogo-mobile {
  display: flex;
  align-items: center;
  font-size: 1.2em; /* Adjust font size as needed */
  margin: 10px 65px ; /* Space around the name */
  color: #ffffff;
  text-align: left;
}

.logo-img-mobile {
  width: 45px; /* Adjust width to desired size */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between image and text */
}


/* Ensure stats table wrapper stays at the bottom of the card */
.stats-table-wrapper-mobile {
  width: 100%; /* Ensure it spans the width of the card */
  background-color: #232c30; /* Background color to match the card */
  border-radius: 5px; /* Rounded corners for the table */
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.8); /* Optional: Add a slight shadow for depth */
  overflow-x: auto; /* Handle overflow for wide tables */
  padding-bottom: 1px;
}

.stats-table-wrapper-mobile table {
  width: 100%; /* Make table take full width */
  border-collapse: collapse; /* Ensure borders collapse for neatness */
}

.stats-table-wrapper-mobile th,
.stats-table-wrapper-mobile td {
  padding: 5px; /* Add padding to table cells */
  border: 1px solid #ddd; /* Add border to table cells */
  text-align: left; /* Align text to the left */
}

.stats-table-wrapper-mobile th {
  background-color: #333; /* Background color for header cells */
  color: #fff; /* Text color for header cells */
}

.stats-table-wrapper-mobile td {
  background-color: #2a2a2a; /* Background color for data cells */
  color: #fff; /* Text color for data cells */
}



body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5; /* Optional: for better visibility */
}

.general-info-mobile {
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  max-width: 300px;
  height: 210px;
  margin: 5px auto;
}

.info-row-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0;
  border-bottom: 1px solid #ddd;
}

.info-row:last-child-mobile {
  border-bottom: none; /* Remove border from the last item */
}

.general-info-mobile .info-row .label {
  font-size: 10px;
  font-weight: bold;
  color: #666;
}


.general-info-mobile .info-row .value {
  font-size: 24px;
  color: #fff;
}
