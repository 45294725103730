.comparison-widget {
    display: flex;    justify-content: space-between; /* Evenly space out the columns */
    width: 100%;
}

.comparison-container {
    display: flex; /* Flexbox to align items horizontally */
    justify-content: space-around; /* Add space around the player info sections */
    margin-top: 20px; /* Add space between title and player info */
}

.player-info-comparison {
    display: flex;
    flex-direction: column;
    align-items: center; /* Align the text to the center */
}

.first-name {
    font-size: 1.2em; /* Regular font size for the first name */
    text-transform: uppercase; /* Make the first name uppercase */
    margin-bottom: 0; /* Remove extra space between first and last name */
}

.last-name {
    font-size: 2.4em; /* Make the last name twice as large */
    font-weight: 900; /* Make the last name bolder */
    text-transform: uppercase; /* Make the last name uppercase */
    margin-top: 0; /* Remove extra space between first and last name */
}

.position-team {
    display: flex;
    justify-content: center; /* Center both position and team horizontally */
    gap: 10px; /* Add some space between position and team */
    margin-top: -40px; /* Add some space between name and position/team */
}

.position-compare {
    font-size: 1em; /* Font size for the player's position */
    color: #ffffff; /* Default color for the player's position */
    background-color: #232c30; /* Default background color for the position box */
    padding: 7px 15px; /* Padding for the position box */
    width: 30px;
    border-radius: 5px; /* Rounded corners for the position box */
    display: inline-block; /* Display as inline-block to adjust width dynamically */
    text-align: center; /* Center text within the box */
}

.team-compare {
    font-size: 1em; /* Font size for the player's position */
    color: #ffffff; /* Default color for the player's position */
    background-color: #232c30; /* Default background color for the position box */
    padding: 7px 15px; /* Padding for the position box */
    width: 30px;
    border-radius: 5px; /* Rounded corners for the position box */
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Horizontally center the text */
    text-align: center; /* Center text within the box */
}



.comparison-bars-container {
    display: flex;
    flex-direction: column; /* Stack bars vertically */
    align-items: center; /* Center the bars */
    margin-top: 30px; /* Add space between player info and bars */
    gap: 10px; /* Add space between each bar */
}

.comparison-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 20px;
    background: linear-gradient(to left, lightgray, white); /* Keep your gradient background */
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin: 10px 0; /* Space between bars */
}

.bar-fill {
    height: 100%;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    line-height: 20px; /* Center text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 1.5s ease-in-out; /* Smooth growing effect for bar fill */
    width: 0%; /* Initial state: bar starts with 0% width */
}

.left-bar {
    flex-direction: row-reverse; /* Keep bars filling from the right towards the left */
}

.right-bar {
    flex-direction: row; /* Keep bars filling from the left towards the right */
}

.bar-label {
    position: absolute;
    font-size: 0.9em;
    font-weight: 700;
    color: white; /* Ensure the label color is white for readability */
    text-shadow: 1px 1px 3px rgba(0, 1, 1, 1.9); /* Keep the subtle drop shadow */

}


/* Specific styles for the left bars */
.left-label {
    right: 5px; /* Align the label on the right side for left-direction bars */
    color: #fff;
}

/* Specific styles for the right bars */
.right-label {
    left: 5px; /* Align the label on the left side for right-direction bars */
    color: #fff;
}


.dropdown-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 80%; /* Adjust as needed for more space */
}


.dropdown-container select {
    width: 45%; /* Make each dropdown take up more space */
    padding: 5px;
    font-size: 1em;
    border-radius: 5px;
    /* border: 1px solid #ccc; */
}


.comparison-row {
    display: flex;
    align-items: stretch; /* Ensure all columns have the same height */
    justify-content: space-between; /* Ensure bars and labels are spaced evenly */
    width: 100%;
    margin-bottom: 10px; /* Add space between rows */
}


.comparison-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-height: 35px; /* Set a consistent minimum height */
}

.select-wrapper .ant-select {
    width: 60%; /* Ensure the dropdown is centrally aligned */
}

.left-column, .right-column {
    width: 43%; /* Allocate 40% of the space to the first and third columns */
      /* border: 1px solid #ccc; */

}

.left-column, .right-column, .middle-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.middle-column {
    width: 14%; /* Adjust the width as needed */
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Align the labels at the bottom */
    align-items: center; /* Center the labels horizontally */
    padding: 0px 0px 10px 0px;
    gap: 32px; /* Add vertical spacing between labels */
    /* border: 1px solid #ccc; Optional: border for visualization */
    font-weight: 900; /* Make the last name bolder */

}

.comparison-bars-container {
    display: flex;
    width: 90%;
    flex-direction: column; /* Stack bars vertically */
    align-items: center; /* Center the bars */
    gap: 10px; /* Add space between each bar */
    margin-top: 20px; /* Add space between player info and bars */
}


/* Mobile-specific adjustments */
@media (max-width: 768px) {
    /* Shrink the entire comparison widget */
    .comparison-widget {
        padding: 5px;
        gap: 5px;
    }

    /* Shrink columns but keep layout */
    .left-column, .right-column {
        width: 35%;
    }

    .middle-column {
        width: 30%;
        gap: 34px;
        font-size: 0.85em;
    }

    /* Shrink text sizes */
    .first-name, .last-name {
        font-size: 0.9em;
    }

    .position-compare, .team-compare {
        font-size: 0.7em;
        padding: 4px 8px;
    }

    /* Ensure space between name and position/team on mobile */
    .position-team {
        margin-top: 0px; /* Increase margin on mobile to add more space */
    }
}

/* Extra small screen adjustments */
@media (max-width: 480px) {
    .left-column, .right-column {
        width: 30%;
    }

    .middle-column {
        width: 40%;
        gap: 37px;
        font-size: 0.7em;
    }

    /* Shrink text sizes more */
    .first-name, .last-name {
        font-size: 0.7em;
    }

    .position-compare, .team-compare {
        font-size: 0.6em;
        padding: 3px 6px;
    }

    /* Add even more space between name and position/team on extra small screens */
    .position-team {
        margin-top: 0px; /* Extra space for very small screens */
    }
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
    .bar-label {
        font-size: 0.75em; /* Smaller font size for mobile screens */
        text-shadow: 1px 1px 3px rgba(0, 1, 1, 1.9); /* Keep the subtle drop shadow */

    }

    .select-wrapper .ant-select {
        width: 80%; /* Further shrink width */
    }

    .select-wrapper .ant-select-selector {
        height: 28px; /* Smaller height for mobile */
        font-size: 0.75em; /* Further reduce font size */
    }

    .select-wrapper .ant-select-item {
        font-size: 0.75em; /* Smaller font size for dropdown items */
    }
}

/* Extra small screen adjustments */
@media (max-width: 480px) {
    .bar-label {
        font-size: 0.55em; /* Further reduce font size for very small screens */
        text-shadow: 1px 1px 3px rgba(0, 1, 1, 1.9); /* Keep the subtle drop shadow */

    }

    .select-wrapper .ant-select {
        width: 80%; /* Further shrink width for very small screens */
    }

    .select-wrapper .ant-select-selector {
        height: 25px; /* Further reduce height */
        font-size: 0.7em; /* Further reduce font size */
    }

    .select-wrapper .ant-select-item {
        font-size: 0.7em; /* Reduce font size for the dropdown items */
    }
}


/* Extra small screen adjustments */
@media (max-width: 1000px) and (min-width: 769px) {

    .middle-column {
        width: 30%;
        gap: 37px;
        font-size: 0.7em;
    }
}



/* Adjustments for screens between 768px and 1200px */
@media (min-width: 1001px) and (max-width: 1200px) {

    .middle-column {
        width: 20%;
        gap: 34px;
        font-size: 0.9em;
    }
}