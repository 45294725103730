.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #713d3d, #7a0505);
    color: #fff;
    font-family: Arial, sans-serif;
  }
  
  .reset-password-card {
    background: #1c1c1c;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
  }
  
  .reset-password-heading {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .reset-password-error {
    color: #e74c3c;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .reset-password-success {
    color: #2ecc71;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .reset-password-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .reset-password-input {
    padding: 10px;
    border: 1px solid #444;
    border-radius: 4px;
    background: #222;
    color: #fff;
    font-size: 14px;
  }
  
  .reset-password-input:focus {
    outline: none;
    border-color: #4ca1af;
  }
  
  .reset-password-button {
    background: #4ca1af;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .reset-password-button:hover {
    background: #3a92a2;
  }
  
  .reset-password-button:disabled {
    background: #7f8c8d;
    cursor: not-allowed;
  }
  