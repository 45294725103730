@import './fonts.css';

@font-face {
    font-family: 'Lato';
    src: url('/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
.container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #000000;
  }

  .button {
    background-color: #ffffff;
    color: #000000;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .button:hover {
    background-color: #cccccc;
  }

  .modalContent {
    display: flex;
    flex-direction: row;
    height: 100%;
    color: #fff;
  }
  .ant-modal {
    background-color: #121212 !important; /* Dark background */
    border-radius: 8px; /* Optional: Keep or adjust */
    box-shadow: none !important; /* Remove shadow */
    padding: 20px !important; /* Adjust padding */
  }

  .ant-modal-close {
    background-color: #121212 !important; /* Dark background */
    border-radius: 8px; /* Optional: Keep or adjust */
    box-shadow: none !important; /* Remove shadow */
    padding: 20px !important; /* Adjust padding */
  }
  

  .leftPane {
    flex: 1;
    padding: 40px;
    background-color: #1c1c1c;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rightPane {
    background-size: 100%; /* Scale the image to cover the entire div */
    background-position: top; /* Center the image */
    background-repeat: no-repeat; /* Prevent image repetition */
    padding-top: 80px;
    flex: 1;
    padding: 40px;
    background-color: #121212;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    

  }
  .patreonButton, .backButton {
    background-color: #000000; /* Patreon brand color */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 8px; /* Rounded corners */
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between logo and text */
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .patreonButton:hover, .backButton:hover {
    background-color: #b64538; /* Slightly darker on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  .orDivider {
    margin: 20px 0;
    text-align: center;
    font-size: 14px;
    color: #888;
  }

  .input {
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #444;
    background-color: #222;
    color: #fff;
  }

  .input:focus {
    border-color: #555;
    outline: none;
  }

  .forgotPassword {
    text-align: right;
    margin-bottom: 20px;
  }

  .forgotPassword a {
    color: #888;
    font-size: 12px;
    text-decoration: none;
  }

  .loginbutton {
    background-color: transparent; /* Remove background color */
    color: #ffffff; /* Black text */
    border: 2px solid #000000; /* Black border */
    border-radius: 25px; /* Rounded corners */
    cursor: pointer;
    font-weight: 700; /* Bold text */
    padding: 10px 20px; /* Adjust padding for size */
    display: flex; /* Flex layout for alignment */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    box-shadow: none; /* Remove shadow */
    transition: all 0.3s ease; /* Smooth hover effect */
    margin-bottom: 10px;
}

.loginbutton:hover {
    background-color: #000; /* Light gray hover background */
    color: #fff; /* Maintain black text */
}

.loginbutton span {
    margin-left: 10px; /* Space between icon and text */
    font-size: 16px; /* Adjust font size */
}



  .loginButton {
    background-color: #4a90e2;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    cursor: pointer;
  }

  .loginButton:hover {
    background-color: #357ab8;
  }

  .loginButton:disabled {
    background-color: #ccc; /* Gray background */
    cursor: not-allowed; /* Change cursor to indicate disabled */
    opacity: 0.6; /* Reduce opacity */
  }

  .signUp {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #888;
  }

  .signUp a {
    color: #4a90e2;
    text-decoration: none;
  }



  .joinNowButton {
    background-color: #b64538; /* Patreon brand color */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 8px; /* Rounded corners */
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between logo and text */
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .joinNowButton:hover {
    background-color: #762d25; /* Slightly darker on hover */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  .headingh2 {
    font-family: 'Rajdhani', sans-serif;
    color: #ffffff;
    font-size: 36px;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 10px;
    text-align: center;
  }
  .headingh3 {
    font-family: 'Rajdhani', sans-serif;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 10px;
    text-align: center;
  }

  .headingp {
    font-family: 'Rajdhani', sans-serif;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 10px;
    text-align: center;
  }