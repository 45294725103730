@import './fonts.css';


.similar-player-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.most-similar-player {
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensure the big circle and text are centered */
    justify-content: center;
    width: 100%; /* Make sure it spans full width for centering */
}

.outer-circle {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    background-color: #ECE1C8; /* Light grey background for the outer circle */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 15px solid #172628; /* Dark blue border around the larger circle */
}

.outer-circle::before,
.outer-circle::after {
    content: "";
    position: absolute;
    background-color: #294245; /* Dark blue color for the lines */
    z-index: 1; /* Make sure it's between inner and outer circles */
}

.outer-circle::before {
    width: 2px; /* Thin vertical line */
    height: 100%; /* Full height of the outer circle */
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.outer-circle::after {
    width: 100%; /* Full width of the outer circle */
    height: 2px; /* Thin horizontal line */
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.inner-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #294245; /* Dark blue color for the inner circle */
    display: flex;
    flex-direction: column; /* Stack percentage and SIMILARITY */
    justify-content: center;
    align-items: center;
    color: white; /* White text for the percentage and similarity */
    position: relative;
    z-index: 2; /* Make sure the inner circle is above the lines */
}

.fill-circle {
    position: absolute;
    width: 100%; 
    height: 100%;
    border-radius: 50%;
    background: conic-gradient(
        #4CAF50 0% 75%, /* Green fill based on percentage */
        transparent 75% 100%
    ); /* Default: 75% filled */
    z-index: 1; /* Behind the percentage text, but above the big circle */
}

.thin-circle {
    position: absolute;
    width: 142px; /* 3/4 of the outer circle (190px * 3/4) */
    height: 142px;
    border-radius: 50%;
    border: 2px solid #294245; /* Thin dark blue circular line */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.percentage {
    font-size: 34px; /* Adjust size of percentage inside the inner circle */
    font-weight: 700;
    margin: 0;
    z-index: 3; /* Keep it above the fill circle */
}

.percentage-smaller {
    font-size: 25px; /* Adjust size of percentage inside the inner circle */
    font-weight: 700;
    margin: 0;
    z-index: 3; /* Keep it above the fill circle */
}

.similarity-text-smaller {
    font-size: 8px; /* Smaller font size for SIMILARITY */
    margin: 0;
    z-index: 3; /* Keep it above the fill circle */
}

.similarity-text {
    font-size: 12px; /* Smaller font size for SIMILARITY */
    margin: 0;
    z-index: 3; /* Keep it above the fill circle */
}

.player-grid {
    display: flex; /* Use flexbox to center the grid */
    justify-content: center; /* Center the player items */
    gap: 20px; /* Spacing between grid items */
    flex-wrap: wrap; /* Allow the grid to wrap on smaller screens */
    width: 100%; /* Ensure grid takes full width */
    max-width: 600px; /* Optional: limit max width to keep things centered */
}

.player-item {
    text-align: center; /* Center the name and year below the circle */
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensure the entire content of player-item is centered */
    justify-content: center;
}

.player-item p {
    margin-top: 0px; /* Add some spacing between the circle and the text */
    text-align: center; /* Ensure the text is centered */
    width: 100%; /* Make sure text spans the width of the item */
}

.player-item .outer-circle {
    width: 90px;
    height: 90px;
}

.player-item .inner-circle {
    width: 70px;
    height: 70px;
    font-size: 18px; /* Adjust percentage font size for smaller circles */
    position: relative;
}

.player-item .thin-circle {
    width: 67px; /* 3/4 of the outer circle (90px * 3/4) */
    height: 67px;
    border: 1px solid #294245; /* Thin dark blue circular line */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

h3 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 700; /* Black */
}

.topsimilarplayer {
    text-align: center;
    font-size: 20px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 700; /* Black */
}

.lower-items {
    text-align: center;
    font-size: 17px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500; /* Black */
}