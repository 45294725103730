/* headercomponent.css */

.header {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(10px); /* Blur the background */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Shadow underneath the header */
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
}

.login-container-override {
  align-items: center !important;
  justify-content: center !important;
  font-size: 20px !important;
  background-color: #1e1e1e !important;
  color: #f96854 !important;
  border: 1px solid #f96854 !important;
  border-radius: 8px !important;
  padding: 10px 10px !important;
}

.logo-container {
  display: flex;
  align-items: center;
}

.centered-header {
  display: flex;
  align-items: flex-end;
}

.logo {
  width: 50px;
  height: 50px;
}

.menu-container {
  flex: 1; /* Take up remaining space */
  justify-content: flex-start; /* Align menu to the left */
  width: 80vw;;
}

.login-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.custom-menu {
  background-color: transparent; /* Set menu background color */
}

/* Styles for regular menu items */
.custom-menu .ant-menu-item {
  font-weight: 700; /* Adjust font weight for menu items */
  color: #c1c1c1; /* Set text color for menu items */
}

/* Add styles for the submenu title */
.custom-menu .ant-menu-submenu-title {
  font-weight: 700; /* Optional: Make it consistent with menu items */
  color: #c1c1c1; /* Set text color for submenu title */
  background: transparent; /* Ensure background matches the menu */
}




/* Remove blur on mobile */
@media (max-width: 768px) {
  .header {
    backdrop-filter: none; /* Disable blur */
  }
}