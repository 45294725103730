/* playerspage.module.css */

/* General page styling */
.playerspage {
    background: linear-gradient(180deg, #2a2a2a, #101010);
    /* Gradient background */
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    /* Prevent horizontal scroll */
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    /* Prevent horizontal scroll on the entire document */
}

/* Centered heading */
.centerText {
    font-size: 28px;
    font-family: 'Lato', sans-serif;
    color: #fff;
    text-align: center;
    margin-top: 50px;
    padding-bottom: 20px;
}

.playerCardContainer {
    position: absolute;
    z-index: 10;
    border-radius: 8px;
    padding: 20px;
    max-width: 90%;
    top: 50%;
    /* Center vertically */
    left: 50%;
    /* Center horizontally */
    transform: translate(-50%, -50%);
    /* Adjust to keep it fully centered */
}

.hidden {
    display: none;
}

/* Table container */
.tableContainer {
    width: 90%;
    margin-top: 30px;
    overflow-x: auto;
    border-radius: 8px;
    padding: 10px;
    padding-bottom: 100px;
}

/* Smooth animation for player card */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -20px);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

.imageRow {
    padding-top: 40px;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    /* Softer border */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* Space between images */
    margin: 40px 40px;
    padding-bottom: 40px;
    /* Larger margin to separate this from other sections */
}

.smallImage {
    width: 20%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    /* Smooth transition for hover effect */
}

.largeImage {
    width: 40%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    /* Smooth transition for hover effect */
}

/* Hover Effect for Both Small and Large Images */
.smallImage:hover,
.largeImage:hover {
    transform: scale(1.1);
    /* Slightly grow the image */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    /* Enhance shadow for hover */
}

/* Responsive Design */
@media (max-width: 768px) {
    .playerCardContainer {
        top: 10%;
        width: 100%;
        max-width: 90%;
        transform: translate(-50%, 0);
        padding: 15px;
    }

    .centerText {
        font-size: 22px;
        margin-top: 30px;
    }

    .tableContainer {
        width: 100%;
        padding: 5px;
    }

    /* Set both sections to 20% width on desktop */
    .playerstooltext {
        width: 90% !important; /* Ensure higher specificity to override default */
        text-align: left; /* Mobile text alignment */
    }
}
.player-card-container-mobile {
    height: 80%;
    position: fixed;
    top: 10%;
    left: 0;
    width: 100%;
    z-index: 10;
    min-height: 300px; /* Use min-height instead of fixed height */
    border-radius: 8px;
  }
.performanceImage {
    width: 100%;
    /* Make the image responsive to container width */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: contain;
    /* Ensures the entire image fits without cropping */
    display: block;
    /* Center the image horizontally when using margin */
}

/* Initial state: hidden and slightly shifted */
.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* When visible: fully opaque and back in place */
.fade-in.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Set both sections to 20% width on desktop */
.playerstooltext,
.playerstoolselection {
    width: 40%;
    text-align: center
}

/* Style for the similarity tool title */
.playerstooltitle {
    font-size: 12px;
    color: rgb(213, 182, 8);
    letter-spacing: 0.1em;
    margin-bottom: 0px;
}

/* Style for the tool description */
.playerstooldescription {
    font-size: 16px;
    color: lightgray;
    line-height: 1.2;
    font-weight: 700;
}