.image-exists .player-img {
    /* styles for images when they exist */
}

.image-missing {
    position: relative; /* Ensure container is positioned */
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    left: 70px; /* Adjust right position */

  }
  
  .image-missing .player-img {
    transform: rotate(320deg); /* Rotate the image 320 degrees */
    opacity: 0.1; /* Set the opacity */
    position: absolute; /* Position the image absolutely */
    z-index: 0; /* Push the image behind other content */

    /* Additional styling as needed */
  }
  