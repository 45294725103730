@font-face {
    font-family: 'Lato';
    src: url('/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('/Rajdhani-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('/Rajdhani-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('/Rajdhani-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('/Rajdhani-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('/Rajdhani-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
