.gamesDisplay {
    font-family: 'Lato', sans-serif;
    padding: 20px;
    background-color: #1e1e1e;
    color: #fff;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
    position: relative;
    overflow: hidden; /* Prevent scroll bar from showing */
    height: 130px; /* Adjusted to show 3 games at a time */
}

.gamesDisplay::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%; /* Cover the bottom half */
    background: linear-gradient(to bottom, rgba(30, 30, 30, 0) 0%, rgba(30, 30, 30, 1) 100%);
    pointer-events: none; /* Ensure it's non-interactive */
    z-index: 1;
    transform: scale(0.95); /* Slightly reduce the size */
    transform-origin: center bottom; /* Scale from the bottom center */
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8rem;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.gameItem {
    padding: 10px 0;
    border-bottom: 1px solid #333;
}

.gameRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.team {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1; /* Ensures each team section takes up one-third of the row */
}

.team:first-child {
    justify-content: flex-end; /* Right-align the first team grouping */
}

.team:last-child {
    justify-content: flex-start; /* Left-align the second team grouping */
}

.teamLogo {
    height: 40px;
    width: auto;
    max-width: 60px;
    object-fit: contain;
    display: block;
}

.teamLogoWrapper {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.smallLogo .teamLogo {
    height: 30px; /* Reduce height for WSH logo */
    max-width: 50px; /* Adjust max width to keep aspect ratio */
}

.abbreviation {
    font-weight: bold;
    font-size: 1rem;
    color: #ccc;
}

.score {
    font-size: 1.5rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #fff;
    text-align: center;
    background-color: #333;
    border-radius: 8px;
    padding: 5px 10px; /* Padding for score bug */
    margin: 0 10px; /* Space between score and team sections */
    border: 1px solid #444;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 8px; /* Space between scores and the hyphen */
}

.score span {
    display: inline-block;
}

.score span:first-child {
    text-align: right; /* Align the first score to the right */
    min-width: 30px; /* Ensure enough space for alignment */
}

.score span:last-child {
    text-align: left; /* Align the second score to the left */
    min-width: 30px; /* Ensure enough space for alignment */
}

.shortDetail {
    font-size: 0.6rem; /* Slightly smaller font for game time */
    font-weight: normal;
    color: #aaa; /* Subtle color for the short detail */
    margin-top: 5px; /* Space between the score box and shortDetail */
    text-align: center; /* Ensure text is centered */
}

.scoreWrapper {
    flex: 1; /* Ensures the score section takes up one-third of the row */
    display: flex;
    flex-direction: column; /* Stack score and shortDetail vertically */
    align-items: center; /* Center horizontally */
    margin-bottom: 10px; /* Add some space below each game block */
}

.scrollContainer {
    display: flex;
    flex-direction: column;
    padding-top: 0px; /* Maintain cropping */
}

  
.gameItem {
    padding: 10px 0;
    border-bottom: 1px solid #333;
    text-align: center;
    flex: none;
}

.gameItem:first-child {
    border-top: 1px solid #333; /* Add a top border to the first game item */
}

.gameRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.pulsingDot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-left: 8px;
    background-color: red;
    border-radius: 50%;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
