.performance-page {
    background-color: #eeeeee;
    min-height: 100vh;
    padding-bottom: 20px;
}

.performance-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

.position-tabs {
    padding: 20px;
}