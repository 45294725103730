/* RostershipPage.module.css */

.rostershipPage {
    background: linear-gradient(180deg, #2a2a2a, #1e1e1e);
    /* Gradient background */
    color: #fff;
    /* White text */
    text-align: center;
    /* Center align text */
    padding-bottom: 200px;
    min-height: 100vh; /* Ensure the background fills the entire viewport height */

}

.center {
    margin: 0px;
}

h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

i {
    font-style: italic;
    color: #ccc;
    /* Lighter gray for subtitle text */
}

.buttonRow {
    margin-top: 15px;
}

.checkbox {
    color: #fff;
}


.performanceImage {
    width: 100%;
    /* Make the image responsive to container width */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: contain;
    /* Ensures the entire image fits without cropping */
    display: block;
    /* Center the image horizontally when using margin */
}

.playerstooltext {
    width: 50%;
    text-align: left;
    margin: 0 auto;
    /* Center horizontally */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center child elements horizontally */
    justify-content: center;
    /* Center child elements vertically */
    padding: 20px;
    border-right: 2px solid #d3d3d3;
    /* Apply a light gray border to the left */


}

/* Style for the similarity tool title */
.playerstooltitle {
    font-size: 12px;
    color: rgb(213, 182, 8);
    letter-spacing: 0.1em;
    margin-bottom: 0px;
}

/* Style for the tool description */
.playerstooldescription {
    font-size: 16px;
    color: lightgray;
    line-height: 1.2;
    font-weight: 700;
}

.rostershipRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* Align items at the top */
    gap: 20px;
    /* Space between the two sections */
    width: 50%;
    /* Take up 50% of the page width */
    margin: 0 auto;
    /* Center the row horizontally */
    padding: 20px 0;
    /* Optional: Add vertical padding for spacing */
}


/* Right section for the input and checkbox */
.inputSection {
    width: 50%;
    text-align: left;
    padding-top: 20px;
    padding-left: 20px;
}

/* Reduce input size */
.input {
    width: 60%;
    /* Set input width to 80% of its container */
    max-width: 150px;
    /* Set a maximum width for smaller screens */
}

/* Reduce input size */
.seerostershipbutton {
    width: 60%;
    /* Set input width to 80% of its container */
    max-width: 150px;
    /* Set a maximum width for smaller screens */
}

/* Style the input and checkbox container */
.center {
    margin-bottom: 10px;
}

.buttonRow {
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkboxRow {
    margin-top: 10px;
    /* Add some space between the button and checkbox */
    text-align: left;
    padding-left: 0px;
}


/* Media queries for mobile responsiveness */
@media (max-width: 768px) {

    .playerstooltext,
    .inputSection {
        width: 100%;
        padding: 10px;
        text-align: center;
        padding-left: 0px;
    }

    .inputSection {
        border-bottom: 2px solid #ffffff;

    }

    .rostershipRow {
        flex-direction: column;
        gap: 10px;
        width: 90%;
    }

    .playerstooltitle {
        font-size: 10px;
        text-align: center;
    }

    .playerstooldescription {
        font-size: 14px;
        text-align: center;
    }

    .input {
        width: 80%;
        max-width: none;
    }

    .seerostershipbutton {
        width: 80%;
        max-width: none;
    }

    .buttonRow,
    .checkboxRow {
        justify-content: center;
        text-align: center;
    }

    .checkboxRow {
        display: none;
    }
}